import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.sass";
import "./styles.css";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";

const duration = 0.3;

const variants = {
	initial: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
		transition: {
			duration: duration,
			delay: duration,
			when: "beforeChildren",
		},
	},
	exit: {
		opacity: 0,
		transition: { duration: duration },
	},
};

const TemplateWrapper = ({ children }) => {
	const { title, description } = useSiteMetadata();
	return (
		<>
			<Helmet>
				<html lang="en" />
				<title>{title}</title>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0"
				></meta>
				<meta name="description" content={description} />

				<link
					rel="icon"
					type="image/png"
					href={`${withPrefix("/")}img/favicon-32x32.png`}
					sizes="32x32"
				/>
				<link
					rel="icon"
					type="image/png"
					href={`${withPrefix("/")}img/favicon-16x16.png`}
					sizes="16x16"
				/>

				<meta name="theme-color" content="#fff" />

				<meta
					property="og:description"
					content="The Buckhorn Supper Club, located on Lake Koshkonong serves steaks, seafood & cocktails in an old-school dining room or on a patio with lake views."
				/>
				<meta property="og:title" content={title} />
				<meta property="og:url" content="/" />
				<meta
					property="og:image"
					content={`${withPrefix("/")}img/og-image.jpg`}
				/>
			</Helmet>
			<Navbar />
			<AnimatePresence>
				<motion.main
					variants={variants}
					initial="initial"
					animate="enter"
					exit="exit"
				>
					<div>{children}</div>
				</motion.main>
			</AnimatePresence>

			<Footer />
		</>
	);
};

export default TemplateWrapper;
