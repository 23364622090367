import React from "react";
import { Link } from "gatsby";

//import logo from "../img/logo.svg";
/*
import logo from "../img/buckhorn-logo.jpg";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import vimeo from "../img/social/vimeo.svg";
*/
import { FaFacebookSquare } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaTripadvisor } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";

import bhbg from "../img/bhbg.svg";

import hoursData from "../global_data/hours.json";

const Footer = class extends React.Component {
	render() {
		return (
			<footer
				className="blackbg has-text-white-ter"
				style={{ background: `url( ${bhbg} )`, backgroundSize: "cover" }}
			>
				<div className="section">
					<div className="container has-text-white-ter">
						<div style={{ maxWidth: "100vw" }} className="columns">
							<div className="column is-4">
								<section className="menu">
									<ul className="menu-list">
										<li>
											<Link to="/" className="navbar-item">
												Home
											</Link>
										</li>
										<li>
											<Link className="navbar-item" to="/menu">
												Our Menu
											</Link>
										</li>
										<li>
											<Link className="navbar-item" to="/events">
												Events At the Buckhorn
											</Link>
										</li>
										<li>
											<Link className="navbar-item" to="/about">
												About The Buckhorn
											</Link>
										</li>
										<li>
											<Link className="navbar-item" to="/contact">
												Contact Us
											</Link>
										</li>
									</ul>
								</section>
							</div>
							<div className="column is-4">
								<section className="menu">
									<ul className="menu-list">
										{hoursData.servingHours.map((data, index) => {
											return (
												<li key={index}>
													<div className="navbar-item white">
														{data.day}: {data.time}
													</div>
												</li>
											);
										})}
									</ul>
								</section>
							</div>
							<div className="column is-4 social">
								<div
									style={{
										display: "block",
										position: "relative",
										marginTop: "30px",
										paddingLeft: "0.75rem",
									}}
								>
									<a
										title="Phone"
										href="tel:16088682653"
										style={{ marginLeft: "0" }}
									>
										<FaPhoneAlt />
									</a>
									<a
										title="Directions"
										href="https://maps.google.com/maps?ll=42.842261,-88.976505&z=17&t=m&hl=en&gl=US&mapclient=embed&daddr=Buckhorn%20Supper%20Club%2011802%20N%20Charley%20Bluff%20Rd%20Milton%2C%20WI%2053563@42.84226109999999,-88.97650519999999"
										className=""
										target="_blank"
										rel="noopener noreferrer"
									>
										<FaMapMarkerAlt />
									</a>
									<a
										title="facebook"
										href="https://www.facebook.com/pages/Buckhorn-Supper-Club/91858759246"
									>
										<FaFacebookSquare />
									</a>

									<a
										title="Trip Advisor"
										href="https://www.tripadvisor.com/Restaurant_Review-g60096-d1234827-Reviews-Buckhorn_Supper_Club-Milton_Wisconsin.html"
									>
										<FaTripadvisor />
									</a>
								</div>
								<div className="address" style={{ paddingLeft: "0.75rem" }}>
									<p>608.868.2653</p>
									<p>11802 N Charley Bluff Rd</p>
									<p>Milton, WI 53563</p>
								</div>
							</div>
						</div>

						<p
							className="white"
							style={{
								textAlign: "center",
								color: "#909090",
								marginTop: "50px",
							}}
						>
							© {new Date().getFullYear()}, Buckhorn Supper Club
							{` `}
							<br />
							All rights reserved.
						</p>
						<p
							className="white"
							style={{
								textAlign: "center",
								color: "#909090",
								fontSize: ".9em",
							}}
						>
							Designed and Developed by{" "}
							<a
								href="https://kloud9creative.com"
								target="_blank"
								rel="noopener noreferrer"
								className="k9"
							>
								Kloud 9 Creative
							</a>
						</p>
					</div>
				</div>
			</footer>
		);
	}
};

export default Footer;
