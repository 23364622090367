import React from "react";
import { Link } from "gatsby";
//import github from "../img/github-icon.svg";
import logo from "../img/buckhorn-logo.jpg";
import { FaFacebookSquare } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaTripadvisor } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";

const Navbar = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			navBarActiveClass: "",
		};
	}

	toggleHamburger = () => {
		// toggle the active boolean in the state
		this.setState(
			{
				active: !this.state.active,
			},
			// after state has been updated,
			() => {
				// set the class in state for the navbar accordingly
				this.state.active
					? this.setState({
							navBarActiveClass: "is-active",
					  })
					: this.setState({
							navBarActiveClass: "",
					  });
			}
		);
	};

	render() {
		return (
			<nav
				className="navbar is-transparent"
				role="navigation"
				aria-label="main-navigation"
			>
				<div className="container">
					<div className="navbar-brand">
						<Link to="/" className="navbar-item" title="Logo">
							<img
								src={logo}
								alt="Buckhorn"
								style={{ maxHeight: "36px" }}
								className="mb0"
							/>
						</Link>
						{/* Hamburger menu */}
						<div
							className={`navbar-burger burger ${this.state.navBarActiveClass}`}
							data-target="navMenu"
							onClick={() => this.toggleHamburger()}
						>
							<span />
							<span />
							<span />
						</div>
					</div>

					<div
						id="navMenu"
						className={`navbar-menu ${this.state.navBarActiveClass}`}
					>
						<div className="navbar-start has-text-centered">
							<Link className="navbar-item" to="/menu" rel="preload">
								Our Menu
							</Link>
							<Link className="navbar-item" to="/events" rel="preload">
								Events at the Buckhorn
							</Link>
							<Link className="navbar-item" to="/about" rel="preload">
								About the Buckhorn
							</Link>

							<Link className="navbar-item" to="/contact" rel="preload">
								Contact Us
							</Link>
						</div>
						<div className="navbar-end has-text-centered">
							<a
								title="Phone"
								href="tel:16088682653"
								className="navbar-item navbar-icons"
							>
								<FaPhoneAlt />
							</a>
							<a
								title="Directions"
								href="https://maps.google.com/maps?ll=42.842261,-88.976505&z=17&t=m&hl=en&gl=US&mapclient=embed&daddr=Buckhorn%20Supper%20Club%2011802%20N%20Charley%20Bluff%20Rd%20Milton%2C%20WI%2053563@42.84226109999999,-88.97650519999999"
								className="navbar-item navbar-icons"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaMapMarkerAlt />
							</a>
							<a
								title="facebook"
								href="https://www.facebook.com/pages/Buckhorn-Supper-Club/91858759246"
								className="navbar-item navbar-icons"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaFacebookSquare />
							</a>

							<a
								title="Trip Advisor"
								href="https://www.tripadvisor.com/Restaurant_Review-g60096-d1234827-Reviews-Buckhorn_Supper_Club-Milton_Wisconsin.html"
								className="navbar-item navbar-icons"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaTripadvisor />
							</a>
						</div>
					</div>
				</div>
			</nav>
		);
	}
};

export default Navbar;
